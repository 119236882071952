(function ($) {
    'use strict';

    // Plugin default options
    var defaultOptions = {
    };

    // If the plugin is a button
    function buildButtonDef (trumbowyg, shortcodeItem) {
        var shortcodeItemValue = '{{ ' + shortcodeItem + ' }}';
        return {
            title: shortcodeItemValue,
            text: shortcodeItemValue,
            fn: function () {
                trumbowyg.saveRange();
                trumbowyg.range.deleteContents();
                trumbowyg.range.insertNode(document.createTextNode(shortcodeItemValue));
                trumbowyg.enable();
            },
        };
    }

    function buildButtonListDef (trumbowyg, shortcodesList) {
        return {
            dropdown: shortcodesList,
            title: 'Insert Shortcode',
            ico: 'shortcode',
        };
    }

    $.extend(true, $.trumbowyg, {
        langs: {
            en: {
                myplugin: 'Shortcodes'
            }
        },
        svgPath: '/_assets/js/trumbowyg/plugins/shortcode/ui/icons/icons.svg',
        plugins: {
            shortcode: {
                init: function (trumbowyg) {
                    trumbowyg.o.plugins.shortcode = $.extend(true, {},
                        defaultOptions,
                        trumbowyg.o.plugins.shortcode || {}
                    );

                    if (trumbowyg.o && trumbowyg.o.extraData && trumbowyg.o.extraData.shortcodes) {
                        var shortcodesList = trumbowyg.o.extraData.shortcodes.split(",");
                        for (var i = 0; i < shortcodesList.length; i++) {
                            var shortcodeItem = shortcodesList[i];
                            trumbowyg.addBtnDef(shortcodeItem, buildButtonDef(trumbowyg, shortcodeItem));
                        }
                        trumbowyg.addBtnDef('shortcode', buildButtonListDef(trumbowyg, shortcodesList));
                    }
                },
            }
        }
    });
})(jQuery);