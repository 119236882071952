Foundation.Joyride.defaults.keyboardAccess = false;

$(document).foundation();
$(document).ready(function() {
    var _updateShortcodesDisplay = function() {
        if ($("[data-js-field-extra='field-toggle']", "[data-js-field='send-form-response-to']").is(":checked")) {
            $(".trumbowyg-dropdown-shortcode button[class^='trumbowyg-CivicApi.']").show();
        } else {
            $(".trumbowyg-dropdown-shortcode button[class^='trumbowyg-CivicApi.']").hide();
        }
    };

    var _updateSendFormResponseToDisplay = function($toggleField) {
        if ($toggleField.is(":checked")) {
            $("[data-js-field-extra='field-toggle-on']").show();
            $("[data-js-field-extra='field-toggle-off']").hide();
            _updateShortcodesDisplay();
        } else {
            $("[data-js-field-extra='field-toggle-on']").hide();
            $("[data-js-field-extra='field-toggle-off']").show();
            _updateShortcodesDisplay();
        }
    };

    var _updateSendFormResponseToListDisplay = function($selectField) {
        $("[data-js-field-extra-form-response-to-list]").hide();
        $("[data-js-field-extra-form-response-to-list] [name='send_to_cc_emails']").prop('disabled', true);

        var selectedValue = $selectField.val();
        if (selectedValue && selectedValue.trim().length) {
            $("[data-js-field-extra-form-response-to-list-" + selectedValue + "]").show();
            $("[data-js-field-extra-form-response-to-list-" + selectedValue + "] [name='send_to_cc_emails']").prop('disabled', false);
            $("[data-js-selectize]").each(function(){
                if (this.selectize) {
                    this.selectize.enable();
                }
            });
        }
    };

    $("[data-js-field='send-form-response-to']").each(function() {
        var $sendToField = $(this);
        if (!$sendToField.hasClass("init")) {
            var $toggleField = $("[data-js-field-extra='field-toggle']", $sendToField);
            $toggleField.on("change", function() {
                var $toggleField = $(this);
                _updateSendFormResponseToDisplay($toggleField);
            });
            
            _updateSendFormResponseToDisplay($toggleField);

            $sendToField.addClass("init");
        }
    });

    $("[data-js-field='send-form-response-to-list']").each(function() {
        var $sendToField = $(this);
        if (!$sendToField.hasClass("init")) {
            var $selectField = $("select", $sendToField);
            $selectField.on("change", function() {
                var $selectField = $(this);
                _updateSendFormResponseToListDisplay($selectField);
            });
            
            _updateSendFormResponseToListDisplay($selectField);

            $sendToField.addClass("init");
        }
    });

    $("[data-js-delete-button]").each(function() {
        var $button = $(this);
        $button.on("click", function(event) {
            if (!confirm($button.data("jsDeleteButton"))) {
                event.preventDefault();
                $button.blur();
                return false;
            } else {
                $button.blur();
            }
        });
    });

    $("[data-js-copy-to-clipboard]").each(function() {
        var $button = $(this);
        $button.on("click", function(event) {
            event.preventDefault();

            var $textHolder = $('<input type="text" style="position: absolute; top: -1000vh; left: -1000vw;">');
            $("body").append($textHolder);
            $textHolder.val($button.data("jsCopyToClipboard")).select();
            document.execCommand("copy");
            $textHolder.remove();
            alert("Copied to clipboad.");

            return false;
        });
    });

    $("[data-js-selectize]").each(function() {
        var $field = $(this);
        switch ($field.data("jsSelectize")) {
            case "email-addresses":
                var REGEX_EMAIL = '([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@' +
                                    '(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';  
                var fieldValue = $field.val().split(";");
                var availableOptions = [];
                if (window.autoCompleteEmails) {
                    availableOptions = window.autoCompleteEmails.concat();
                }
                
                for (var i = 0; i < fieldValue.length; i++) {
                    if (fieldValue[i] && fieldValue[i].trim().length > 0) {
                        availableOptions.push({
                            value: fieldValue[i].trim(),
                            text: fieldValue[i].trim()
                        });
                    }
                }


                $field.selectize({
                    plugins: ['remove_button'],
                    persist: false,
                    delimiter: ';',
                    options: availableOptions,
                    createOnBlur: true,
                    create: true,
                    createFilter: function(input) {
                        var match, regex;
                
                        regex = new RegExp('^' + REGEX_EMAIL + '$', 'i');
                        match = input.match(regex);
                        if (match) return !this.options.hasOwnProperty(match[0]);
                
                        return false;
                    },

                });
                break;
            case "civic-api-levels":
                $field.selectize({
                    plugins: ['remove_button'],
                    selectOnTab: true,
                    options: [
                        {value: 'administrativeArea1', text: "Administrative Area 1"},
                        {value: 'administrativeArea2', text: "Administrative Area 2"},
                        {value: 'country', text: "Country"},
                        {value: 'international', text: "International"},
                        {value: 'locality', text: "Locality"},
                        {value: 'regional', text: "Regional"},
                        {value: 'special', text: "Special"},
                        {value: 'subLocality1', text: "Sub Locality 1"},
                        {value: 'subLocality2', text: "Sub Locality 2"},
                    ],
                });
                break;
            case "civic-api-roles":
                $field.selectize({
                    plugins: ['remove_button'],
                    selectOnTab: true,
                    options: [
                        {value: 'deputyHeadOfGovernment', text: "Deputy Head Of Government"},
                        {value: 'executiveCouncil', text: "Executive Council"},
                        {value: 'governmentOfficer', text: "Government Officer"},
                        {value: 'headOfGovernment', text: "Head Of Government"},
                        {value: 'headOfState', text: "Head Of State"},
                        {value: 'highestCourtJudge', text: "Highest Court Judge"},
                        {value: 'judge', text: "Judge"},
                        {value: 'legislatorLowerBody', text: "Legislator Lower Body"},
                        {value: 'legislatorUpperBody', text: "Legislator Upper Body"},
                        {value: 'schoolBoard', text: "School Board"},
                        {value: 'specialPurposeOfficer', text: "Special Purpose Officer"},
                    ],
                });
                break;
            default:
                break;
        }
    });

    var manageDeleteButtonDisplay = function($tabContainer, $deleteButton) {
        var totalTabs = $(".tabs-content .tabs-panel", $tabContainer).length;
        
        if (totalTabs > 1) {
            $deleteButton.show();
        } else {
            $deleteButton.hide();
        }
    };

    $("[data-js-add-tab]").each(function() {
        var $addButton = $(this);
        var $tabNavigation = $("#" + $addButton.data("jsAddTab"));
        var $tabContainer = $tabNavigation.parent();
        var $tabPanelTemplate = $(".tabs-template", $tabContainer);
        var $deleteButton = $("[data-js-delete-tab]", $tabContainer);
        
        $addButton.on("click", function(event) {
            event.stopPropagation();
            
            var newTabIndex = $(".tabs-content .tabs-panel", $tabContainer).length + 1;
            var $tabTemplate = $("li", $tabNavigation).first();
            var $tab = $tabTemplate.clone();
            
            $("strong", $tab).text($tab.data("jsTabTemplateLabel") + " " + newTabIndex);
            $("a", $tab).attr("href", "#" + $tab.data("jsTabTemplateHref") + newTabIndex);
            $tabNavigation.append($tab);
            
            var $newTabPanel = $(".tabs-panel", $tabPanelTemplate).clone();
            $newTabPanel.attr("id", $tab.data("jsTabTemplateHref") + newTabIndex);
            $newTabPanel.removeClass("js-template");
            $(".tabs-content", $tabContainer).append($newTabPanel);

            var tabs = new Foundation.Tabs($tabNavigation);
            $tabNavigation.foundation("_collapse");
            $tabNavigation.foundation("_openTab", $tab);

            manageDeleteButtonDisplay($tabContainer, $deleteButton);
            _initWysiwygEditors();

            return false;
        });
    });

    $("[data-js-delete-tab]").each(function() {
        var $deleteButton = $(this);
        var $tabNavigation = $("#" + $deleteButton.data("jsDeleteTab"));
        var $tabContainer = $tabNavigation.parent();

        $deleteButton.on("click", function(event) {
            event.stopPropagation();

            var activeIndex = $(".is-active", $tabNavigation).index();
            var tabName = $(".is-active strong", $tabNavigation).text();

            if (confirm('Are you sure you want to delete ' + tabName + '?')) {
                $(".is-active", $tabNavigation).remove();
                $(".is-active", $tabContainer).remove();

                var tabs = new Foundation.Tabs($tabNavigation);
                var tabIndexToSelect = (activeIndex - 1 >= 0) ? activeIndex - 1 : 0;
                var $tab = $("li:eq(" + tabIndexToSelect + ")", $tabNavigation);
                $tabNavigation.foundation("_openTab", $tab);
            }

            manageDeleteButtonDisplay($tabContainer, $deleteButton);

            return false;
        });

        manageDeleteButtonDisplay($tabContainer, $deleteButton);
    });

    var _initWysiwygEditors = function() {
        $("[data-js-wysiwyg]").each(function() {
            var $textAreaContainer = $(this);

            if (!$textAreaContainer.hasClass("js-template")) {
                var $textArea = $("textarea", $textAreaContainer);
        
                $textArea.trumbowyg({
                    btns: [
                        ['strong', 'em'],
                        ['link'],
                        ['shortcode'],
                        ['viewHTML'],
                    ],
                    semantic: false,
                    removeformatPasted: true,
                    extraData: {
                        shortcodes: $textAreaContainer.data("js-wysiwyg-shortscodes"),
                    }
                });
                _updateShortcodesDisplay();
            }
        });
    };
    _initWysiwygEditors();

    var _updateToggledColumnValue = function(event) {
        event.stopPropagation();

        var $toggleControl = $(this);
        var columnName = $toggleControl.data("jsToggleColumns");
        if ($("[data-js-toggle-value-" + columnName + "]").val() == "true") {
            $("[data-js-toggle-value-" + columnName + "]").val("false");
        } else {
            $("[data-js-toggle-value-" + columnName + "]").val("true");
        }

        _updateToggleColumns($toggleControl);

        return false;
    };

    var _updateToggleColumns = function(toggleControl) {
        var columnName = toggleControl.data("jsToggleColumns");
        var toggleValue = $("[data-js-toggle-value-" + columnName + "]").val();

        if (toggleValue == "true") {
            $("[data-js-toggle-" + columnName + "=true]").show();
            $("[data-js-toggle-" + columnName + "=false]").hide();
        } else {
            $("[data-js-toggle-" + columnName + "=true]").hide();
            $("[data-js-toggle-" + columnName + "=false]").show();
        }
        
        $("[data-js-toggle-value-" + columnName + "]").val(toggleValue);
    };

    $("[data-js-toggle-columns]").each(function() {
        var $toggleControl = $(this);

        $toggleControl.on("click", _updateToggledColumnValue);

        _updateToggleColumns($toggleControl);
    });

    if ($("[data-joyride]").length > 0) {
        $("[data-joyride-link]").show().removeClass("hide");
    } else {
        $("[data-joyride-link]").hide();
    }

    $("[data-js-edit-email]").on("click", function() {
        var $button = $(this);
        var $container = $(this).parent().parent();
        var $nameCell = $("[data-js-edit-civicmember-name]", $container);
        var $originalEmailCell = $("[data-js-edit-email-original-value]", $container);
        var $newEmailCell = $("[data-js-edit-email-new-value]", $container);
        var askForEmailAddress = true;
        var emailAddress = $newEmailCell.text();
        var stateValue = $button.data("jsEditEmail");

        while (askForEmailAddress == true) {
            emailAddress = prompt("Enter an email address for '" + $nameCell.text() + "'", emailAddress);
            if (emailAddress == null) {
                askForEmailAddress = false;
            } else if (emailAddress.trim().length == 0) {
                askForEmailAddress = false;
                if ($originalEmailCell.text().length > 0) {
                    // Show Loader
                    $("[data-js-edit-email-edit]", $button).addClass("hide");
                    $("[data-js-edit-email-loader]", $button).removeClass("hide");

                    // Make ajax call
                    $.post(window.location.href, {name: $nameCell.text(), email: '', state: stateValue, action: 'email'}, function(data) {
                        // Update row
                        $newEmailCell.text('');
                        $originalEmailCell.removeClass("has-custom-value");
                        $("[data-js-edit-email-edit]", $button).removeClass("hide");
                        $("[data-js-edit-email-loader]", $button).addClass("hide");
                    });
                }
            } else {
                var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailAddress.match(mailformat)) {
                    // Show Loader
                    $("[data-js-edit-email-edit]", $button).addClass("hide");
                    $("[data-js-edit-email-loader]", $button).removeClass("hide");
                    
                    // Make ajax call
                    $.post(window.location.href, {name: $nameCell.text(), email: emailAddress, state: stateValue, action: 'email'}, function(data) {
                        // Update row
                        $newEmailCell.text(emailAddress);
                        if (emailAddress.trim().length > 0) {
                            $originalEmailCell.addClass("has-custom-value");
                        } else {
                            $originalEmailCell.removeClass("has-custom-value");
                        }
                        $("[data-js-edit-email-edit]", $button).removeClass("hide");
                        $("[data-js-edit-email-loader]", $button).addClass("hide");
                    });
                    askForEmailAddress = false;
                } else {
                    alert('Invalid email address');
                }
            }
        }
    });

    $("[data-js-edit-salutation]").on("click", function() {
        var $button = $(this);
        var $container = $(this).parent().parent();
        var $nameCell = $("[data-js-edit-civicmember-name]", $container);
        var $originalSalutationCell = $("[data-js-edit-salutation-original-value]", $container);
        var $newSalutationCell = $("[data-js-edit-salutation-new-value]", $container);
        var askForSalutation = true;
        var salutation = $newSalutationCell.text();
        var stateValue = $button.data("jsEditSalutation");

        while (askForSalutation == true) {
            salutation = prompt("Enter a salutation for '" + $nameCell.text() + "'", salutation);
            if (salutation == null) {
                askForSalutation = false;
            } else if (salutation.trim().length == 0) {
                askForSalutation = false;
                if ($originalSalutationCell.text().length > 0) {
                    // Show Loader
                    $("[data-js-edit-salutation-edit]", $button).addClass("hide");
                    $("[data-js-edit-salutation-loader]", $button).removeClass("hide");

                    // Make ajax call
                    $.post(window.location.href, {name: $nameCell.text(), salutation: '', state: stateValue, action: 'salutation'}, function(data) {
                        // Update row
                        $newSalutationCell.text('');
                        $originalSalutationCell.removeClass("has-custom-value");
                        $("[data-js-edit-salutation-edit]", $button).removeClass("hide");
                        $("[data-js-edit-salutation-loader]", $button).addClass("hide");
                    });
                }
            } else {
                // Show Loader
                $("[data-js-edit-salutation-edit]", $button).addClass("hide");
                $("[data-js-edit-salutation-loader]", $button).removeClass("hide");
                
                // Make ajax call
                $.post(window.location.href, {name: $nameCell.text(), salutation: salutation, state: stateValue, action: 'salutation'}, function(data) {
                    // Update row
                    $newSalutationCell.text(salutation);
                    if (salutation.trim().length > 0) {
                        $originalSalutationCell.addClass("has-custom-value");
                    } else {
                        $originalSalutationCell.removeClass("has-custom-value");
                    }
                    $("[data-js-edit-salutation-edit]", $button).removeClass("hide");
                    $("[data-js-edit-salutation-loader]", $button).addClass("hide");
                });
                askForSalutation = false;
            }
        }
    });

    $("[data-js-form-list-client-filter]").each(function() {
        var $filterField = $(this);
        $filterField.on('change', function() {
            var newUrl = new URL(window.location.href);
            if (parseInt($filterField.val()) > 0) {
                newUrl.searchParams.set('client', parseInt($filterField.val()));
            } else {
                newUrl.searchParams.delete('client');
            }
            window.location.href = newUrl.toString();
        });
    });

    $("[data-js-user-edit-role] select").each(function() {
        var $selectField = $(this);
        $selectField.on('change', function() {
            if ($("option:selected", $selectField).text() == 'Client Reports') {
                $("[data-js-user-edit-client]").show();
            } else {
                $("[data-js-user-edit-client]").hide();
            }
        });
        $selectField.change();
    });
});